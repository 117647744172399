import React, { useState, useEffect, createContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { View } from '@aws-amplify/ui-react';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import './NavBar.css';


const NavBar = () =>  {

  const navigate = useNavigate();
  const location = useLocation();

  const [SignedIn, setSignedIn] = React.useState(false);

  async function clickSignOut() {
    await Auth.signOut();
    setSignedIn(false);
    navigate("/");
    window.location.reload(true);
  }

  useEffect(() => {
    fetchUser();
  }, [location]);

  async function fetchUser()  {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setSignedIn(true);
      })
      .catch(err => {
        setSignedIn(false);
      });
  }

  return ( 
    <>
    { SignedIn ? <Navbar  bg="dark" expand="false" sticky="top">
    <Container>
      <Navbar.Brand href="/">
        
        <StorageImage alt="Logo" imgKey="logo.svg" accessLevel="guest" />
                      
      </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
          >
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link className="btn btn-white mb-2" href="/">Locations</Nav.Link>
              <Nav.Link className="btn btn-white mb-2" href="/account">My Tickets </Nav.Link>
              <Nav.Link  className="btn btw-white" onClick={clickSignOut}>Sign Out</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        </Container>
      </Navbar> : null }
      </>
  );
};

export default NavBar;