/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      locationsID
      Tickets {
        nextToken
        __typename
      }
      qr
      title
      description
      locationName
      endDate
      status
      userID
      host
      why
      image
      tickets_sold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locationsID
        qr
        title
        description
        locationName
        endDate
        status
        userID
        host
        why
        image
        tickets_sold
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByLocationsID = /* GraphQL */ `
  query EventsByLocationsID(
    $locationsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByLocationsID(
      locationsID: $locationsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationsID
        qr
        title
        description
        locationName
        endDate
        status
        userID
        host
        why
        image
        tickets_sold
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTickets = /* GraphQL */ `
  query GetTickets($id: ID!) {
    getTickets(id: $id) {
      id
      eventID
      eventName
      status
      expirationDate
      locationsID
      ownerID
      ownerName
      stripeSessionID
      ownerUserName
      locationName
      why
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        eventName
        status
        expirationDate
        locationsID
        ownerID
        ownerName
        stripeSessionID
        ownerUserName
        locationName
        why
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ticketsByEventID = /* GraphQL */ `
  query TicketsByEventID(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByEventID(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventName
        status
        expirationDate
        locationsID
        ownerID
        ownerName
        stripeSessionID
        ownerUserName
        locationName
        why
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ticketsByLocationsID = /* GraphQL */ `
  query TicketsByLocationsID(
    $locationsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByLocationsID(
      locationsID: $locationsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        eventName
        status
        expirationDate
        locationsID
        ownerID
        ownerName
        stripeSessionID
        ownerUserName
        locationName
        why
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocations = /* GraphQL */ `
  query GetLocations($id: ID!) {
    getLocations(id: $id) {
      id
      address
      title
      Events {
        nextToken
        __typename
      }
      userID
      phone
      image
      event_total
      amount_collected
      customer_count
      Tickets {
        nextToken
        __typename
      }
      hours_list
      stripeAccountID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        title
        userID
        phone
        image
        event_total
        amount_collected
        customer_count
        hours_list
        stripeAccountID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
