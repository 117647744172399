import React, { useState, useEffect } from "react";
import { Heading, Collection } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom';
import { API } from "aws-amplify";
import { listTickets } from "../../graphql/queries";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { checkSignedIn } from "../../helpers/userHelpers";

const Tickets = ({ location }) => {
  const [AllTickets, setAllTickets] = React.useState("");
  let { id } = useParams();

  const [SignedIn, setSignedIn] = React.useState(false);
  const [UserID, setUserID] = React.useState("");
  const [UserLevel, setUserLevel] = React.useState("customer");
  async function fetchUser() {
   await checkSignedIn().then(response => {
    if (response) {
     setSignedIn(true);
     setUserID(response.id);
     setUserLevel(response.level);
    }
   });
 }
 
  async function getAllTickets() {
    // List all items
    const allTickets = await API.graphql({
      query: listTickets,
    });
    setAllTickets(allTickets['data']['listTickets']['items']);
  }
  
  useEffect(() => {
    fetchUser();
    fetchAll();
  }, [location]);
  
  async function fetchAll()  {
    await getAllTickets();
  }

  return (
    <Container>
      <Row className="justify-content-md-center pt-4 pb-5">
        <Col className="text-center">
          <Heading level={1}>All Tickets</Heading>
        </Col>
      </Row>
      <Row>
        <Collection items={AllTickets} type="grid" templateColumns="1fr 1fr 1fr 1fr" gap="15px"
        isSearchable
        isPaginated
        itemsPerPage={9}
        searchPlaceholder="Type to search...">
          {(ticket, index) => (
            <Card key={index}>
              <Card.Body className="text-center">
                <Card.Title>{ticket.id}</Card.Title>
                <Card.Text>{ticket.eventID}</Card.Text>
                <Card.Text>{ticket.owner}</Card.Text>
              </Card.Body>
            </Card>
          )}
        </Collection>
      </Row>
    </Container>
  );
};

export default Tickets;