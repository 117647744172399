
import { Text, Flex, Heading, View } from '@aws-amplify/ui-react';
import { Authenticator  } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const SignUp = ({ location }) => {
  const navigate = useNavigate();
  const [locationSet, setlocationSet] = React.useState(false);
  const [searchParams] = useSearchParams();



  async function fetchLocation() {
    var loc = searchParams.get('loc');
    if (loc) {setlocationSet(loc);}
 }

  useEffect(() => {
    fetchLocation();
  }, [location]);
  
  return (
    <Container fluid className="signin signup">
      <Row>
        <Col sm={12} lg={6} className="left">
          <View className="inner">
          <Heading level={1} className="text-center">Sign Up</Heading>
          { (locationSet) ? <Text>To purchase a ticket you must first create an account!</Text> : null }
          <Authenticator initialState="signUp">
            
            { (locationSet) ? <View>
            <Text textAlign="center">You are now signed up! Click below to return to the page to finish purchasing your ticket</Text>
            <Flex justifyContent="center" marginTop="20px" marginBottom="16px">
              <Button onClick={() => navigate(`/event/${locationSet}`)} >Return</Button>
            </Flex>
            </View> : <View>
            <Text textAlign="center">You are now signed up! Please proceed to one of the following pages.</Text>
            <Flex justifyContent="center" marginTop="20px" marginBottom="16px">
              <Button onClick={() => navigate("/")} >Locations</Button>
              <Button onClick={() => navigate("/account")} >My Tickets</Button>
            </Flex>
            </View> }
          </Authenticator>
          <Text>Already have an account? <a href="/signin">Sign in</a></Text>
          </View>
        </Col>
        <Col sm={12} lg={6} className="right">
          <View className="inner">
            <Heading level={2} className="white welcome">Welcome</Heading>
              <Text className="white subheader">Sign in to continue access</Text>
          </View>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;