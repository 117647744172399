
import { createLocations, deleteLocations, updateLocations, deleteEvent } from '../graphql/mutations';
import { listLocations, getLocations, listEvents } from '../graphql/queries';
import { API, Auth } from "aws-amplify";
import { AES, enc } from 'crypto-js';


export async function getAllLocations() {
    // List all items
    const allLocations = await API.graphql({
      query: listLocations,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return allLocations['data']['listLocations']['items'];
}

export async function getLocationInfo(SignedIn, id) {
  // Get a specific item
  const oneLocations = await API.graphql({
    query: getLocations,
    variables: { id: id },
    authMode: SignedIn ?  'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
  });
  return oneLocations['data']['getLocations'];
}


export async function createLocation(title, address, mon_hours, tues_hours, wed_hours, thur_hours, fri_hours, sat_hours, sun_hours, phone, stripeAccountID, users, image) {
  var hours =  [mon_hours, tues_hours, wed_hours, thur_hours, fri_hours, sat_hours, sun_hours];

  
  const encryptedValue = AES.encrypt(stripeAccountID, process.env.REACT_APP_SECRET);
  const newLocations = await API.graphql({
      query: createLocations,
      variables: {
          input: {
          "address": address,
          "title": title,
          "hours_list": hours,
          "phone": phone,
          "userID": users,
          "image": image,
          "customer_count": 0,
          "event_total":0,
          "amount_collected":0,
          "stripeAccountID":encryptedValue.toString()
          }
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}

export async function addEventToLocation(SignedIn, id) {
  var return_location = await getLocationInfo(SignedIn, id);
  var old_total = return_location.event_total;
  var new_total = old_total + 1;
  var details = {
    id: id,
    "event_total": new_total
  };
  
  const updatedLocations = await API.graphql({
      query: updateLocations,
      variables: { input: details },
      authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}

export async function removeEventToLocation(SignedIn, id) {
  var return_location = await getLocationInfo(SignedIn, id);
  var old_total = return_location.event_total;
  var new_total = old_total - 1;
  if (new_total < 0) {new_total = 0;}
  var details = {
    id: id,
    "event_total": new_total
  };
  
  const updatedLocations = await API.graphql({
      query: updateLocations,
      variables: { input: details },
      authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}

export async function updateLocation(id, title, address, mon_hours, tues_hours, wed_hours, thur_hours, fri_hours, sat_hours, sun_hours, phone, stripeAccountID, users, image) {
  var hours =  [mon_hours, tues_hours, wed_hours, thur_hours, fri_hours, sat_hours, sun_hours];

  var details = {
    id: id
  };

  if (title) { details.title = title; } 
  if (address) { details.address = address; } 
  if (hours) { details.hours_list = hours; } 
  if (phone) { details.phone = phone; } 
  if (users) { details.userID = users; } 
  if (image) { details.image = image; } 
  if (stripeAccountID) {
    const encryptedValue = AES.encrypt(stripeAccountID, process.env.REACT_APP_SECRET).toString();;
    details.stripeAccountID = encryptedValue;
  } 
  
  const updatedLocations = await API.graphql({
      query: updateLocations,
      variables: { input: details },
      authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}


export async function deleteLocation(id) {
    if (window.confirm("Are you sure you want to delete this location?")) {
      const allEvents = await API.graphql({
        query: listEvents,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      var items = allEvents['data']['listEvents']['items'];
      items.forEach((item) => {
        if (item['locationsID'] == id) {
          const deletedEvents = API.graphql({
            query: deleteEvent,
              variables: {
                  input: {
                      id: item['id']
                  }
              },
              authMode: "AMAZON_COGNITO_USER_POOLS",
          });
        }
      });
      const deletedLocations = await API.graphql({
          query: deleteLocations,
          variables: {
              input: {
                  id: id
              }
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    }
  }