/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0utue1amb4.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "confirmstripe",
            "endpoint": "https://3brsgo6p94.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://3jmj2eibfrgqlbd35jkowg3dvy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-imm7bygucrgfhbe2croprf54va",
    "aws_cognito_identity_pool_id": "us-east-1:9b4eef9a-4481-47a6-80dc-8adbee472bd2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3VmWeCi5m",
    "aws_user_pools_web_client_id": "778l7fo8qlvogcn40tnb2cnv5g",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hognbonesapp-storage-719f9a3b115151-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
