import React, { useState } from "react";
import { Home } from './views/Home';
import { Account } from './views/Account';
import { SignIn } from './views/SignIn';
import { SignUp } from './views/SignUp';
import { Location } from './views/Location';
import { Event } from './views/Event';
import { Landing } from './views/Landing';
import { Tickets } from './views/Tickets';
import { NavBar } from './components/NavBar';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Route, Routes, Navigate } from 'react-router-dom';
import { View } from "@aws-amplify/ui-react";
import { studioTheme } from "./ui-components";

export const Switch = () => {
  return (
    <>
      <NavBar />
      <View className="main">
        <Routes>
          <Route path='/' element={
            <ProtectedRoute>
              <Home/>
            </ProtectedRoute>
            } />
          <Route path='/signup' element={<SignUp/>} />
          <Route path='/signin' element={<SignIn/>} />
          <Route path="/locations/:id" element={
            <ProtectedRoute>
              <Location/>
            </ProtectedRoute>
          } />
          <Route path="/event/:id" element={<Landing/>} />
          <Route path="/event/backend/:id" element={
                  <ProtectedRoute>
                    <Event/>
                  </ProtectedRoute>
          } />
          <Route path="/tickets" element={
            <ProtectedRoute>
              <Tickets/>
            </ProtectedRoute>
          } />
          <Route path='/Home' element={<Navigate to="/" replace={true} />} />
          <Route path='/Locations' element={<Navigate to="/" replace={true} />} />
          <Route path='/Event' element={<Navigate to="/" replace={true} />} />
          <Route  path="/account" element={
                  <ProtectedRoute>
                      <Account />
                  </ProtectedRoute>
            } />
        </Routes>
      </View>
    </>
  );
};